import {Button} from "react-bootstrap";
import {Eclipse} from "react-svg-spinners";
import React from "react";


export type BlockButtonProps = {
    label: string | React.ReactElement
    loading?:boolean
    type: "button"|"submit"
    className?:string
    variant?:string
    onClick?: any
}

function BlockButton({label="", loading=false, type="button", className="", variant="primary",onClick}:BlockButtonProps){

    const renderLabel = () => {
        if(typeof label === "string"){
            return label
        }else{
            return (label)
        }
    }


    return (
        <>
            {type === "submit" ? (
                <div className="d-grid ">
                    <Button variant={variant} type={type} className={className} disabled={loading}>
                        <div className="d-flex justify-content-center">
                            {loading && (
                                <span><Eclipse/></span>
                            )}
                            <span className="mx-2 fw-semibold">{renderLabel()}</span>
                        </div>
                    </Button>
                </div>
            ):(
                <div className="d-grid ">
                    <Button variant={variant} type={type} className={className} disabled={loading} onClick={onClick}>
                        <div className="d-flex justify-content-center">
                            {loading && (
                                <span><Eclipse/></span>
                            )}
                            <span className="mx-2 fw-semibold">{renderLabel()}</span>
                        </div>
                    </Button>
                </div>
            )}
        </>
    )
}

export default BlockButton