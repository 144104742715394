import {useLayoutData} from "../../../context/LayoutContext";
import {IoQrCodeOutline} from "react-icons/io5";
import {IoIosLink} from "react-icons/io";
import { IoAnalytics } from "react-icons/io5";
import {Link} from "react-router-dom";
import {useNavigate} from "react-router";
import { GiBrain } from "react-icons/gi";
import QrMonthlyUsage from "./QrMonthlyUsage";
import {useAuthData} from "../../../context/AuthContext";
import {useAppData} from "../../../context/AppDataContext";
import LinkMonthlyUsage from "./LinkMonthlyUsage";
import {Button} from "react-bootstrap";
import BlockButton from "../buttons/BlockButton";
import {AiFillApi} from "react-icons/ai";
import {FiPower} from "react-icons/fi";


function LeftSubMenu(){
    const {layoutConfig, lightMode} = useLayoutData()
    const navigate = useNavigate()
    const {account} = useAuthData()
    const {subscriptionRole} = useAppData()

    return (
        <>
            {(layoutConfig && layoutConfig.includeLeftSubMenu) ? (
                <ul id="c-elements" datatype="menu" className="c-elements c-d-flex   ps-4 pe-4 py-2 flex-column" style={{minWidth: "25%"}}>
                    {/*Parent div*/}
                    {/*<li className="div">*/}
                    {/*    <input type="checkbox" id="div1"/>*/}
                    {/*    <label htmlFor="div1">*/}
                    {/*        <div className="caret icon-sm">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
                    {/*                 strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">*/}
                    {/*                <path strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*                      d="m8.25 4.5 7.5 7.5-7.5 7.5"/>*/}
                    {/*            </svg>*/}

                    {/*        </div>*/}
                    {/*        <div className="icon-sm ms-1 me-2">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                    {/*                 className="bi bi-app" viewBox="0 0 16 16">*/}
                    {/*                <path*/}
                    {/*                    d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4z"/>*/}
                    {/*            </svg>*/}

                    {/*        </div>*/}
                    {/*        <div className="div-tag">Links</div>*/}
                    {/*    </label>*/}
                    {/*    <div className="content">*/}

                    {/*        /!*Nested Div*!/*/}
                    {/*        <div className="div">*/}
                    {/*            <input type="checkbox" id="div2"/>*/}
                    {/*            <label htmlFor="div2">*/}
                    {/*                <div className="caret icon-sm">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
                    {/*                         strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">*/}
                    {/*                        <path strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*                              d="m8.25 4.5 7.5 7.5-7.5 7.5"/>*/}
                    {/*                    </svg>*/}

                    {/*                </div>*/}
                    {/*                <div className="icon-sm ms-1 me-2">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                    {/*                         fill="currentColor" className="bi bi-app" viewBox="0 0 16 16">*/}
                    {/*                        <path*/}
                    {/*                            d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4z"/>*/}
                    {/*                    </svg>*/}

                    {/*                </div>*/}
                    {/*                <div className="div-tag">Child Div</div>*/}
                    {/*            </label>*/}
                    {/*            <div className="content">*/}

                    {/*                /!*Second Nested Div*!/*/}
                    {/*                <div className="div">*/}
                    {/*                    <input type="checkbox" id="div3"/>*/}
                    {/*                    <label htmlFor="div3">*/}
                    {/*                        <div className="caret icon-sm">*/}
                    {/*                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"*/}
                    {/*                                 viewBox="0 0 24 24" strokeWidth="1.5"*/}
                    {/*                                 stroke="currentColor" className="w-6 h-6">*/}
                    {/*                                <path strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*                                      d="m8.25 4.5 7.5 7.5-7.5 7.5"/>*/}
                    {/*                            </svg>*/}

                    {/*                        </div>*/}
                    {/*                        <div className="icon-sm ms-1 me-2">*/}
                    {/*                            <svg xmlns="http://www.w3.org/2000/svg" width="16"*/}
                    {/*                                 height="16" fill="currentColor" className="bi bi-app"*/}
                    {/*                                 viewBox="0 0 16 16">*/}
                    {/*                                <path*/}
                    {/*                                    d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4z"/>*/}
                    {/*                            </svg>*/}

                    {/*                        </div>*/}
                    {/*                        <div className="div-tag">Grandchild Div</div>*/}
                    {/*                    </label>*/}
                    {/*                    <div className="content">*/}
                    {/*                        <div className="div-tag">Empty</div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                /!*    END - Second Nested Div*!/*/}

                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        /!*    END - Nested Div*!/*/}

                    {/*    </div>*/}
                    {/*</li>*/}


                    {/*/!*Parent div*!/*/}
                    {/*<li className="div">*/}
                    {/*    <input type="checkbox" id="div4"/>*/}
                    {/*    <label htmlFor="div4">*/}
                    {/*        <div className="caret icon-sm">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
                    {/*                 strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">*/}
                    {/*                <path strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*                      d="m8.25 4.5 7.5 7.5-7.5 7.5"/>*/}
                    {/*            </svg>*/}

                    {/*        </div>*/}
                    {/*        <div className="icon-sm ms-1 me-2">*/}
                    {/*            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
                    {/*                 className="bi bi-app" viewBox="0 0 16 16">*/}
                    {/*                <path*/}
                    {/*                    d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4z"/>*/}
                    {/*            </svg>*/}

                    {/*        </div>*/}
                    {/*        <div className="div-tag">Parent Div</div>*/}
                    {/*    </label>*/}
                    {/*    <div className="content">*/}

                    {/*        /!*Nested Div*!/*/}
                    {/*        <div className="div">*/}
                    {/*            <input type="checkbox" id="div5"/>*/}
                    {/*            <label htmlFor="div5">*/}
                    {/*                <div className="caret icon-sm">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"*/}
                    {/*                         strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">*/}
                    {/*                        <path strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*                              d="m8.25 4.5 7.5 7.5-7.5 7.5"/>*/}
                    {/*                    </svg>*/}

                    {/*                </div>*/}
                    {/*                <div className="icon-sm ms-1 me-2">*/}
                    {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"*/}
                    {/*                         fill="currentColor" className="bi bi-app" viewBox="0 0 16 16">*/}
                    {/*                        <path*/}
                    {/*                            d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4z"/>*/}
                    {/*                    </svg>*/}

                    {/*                </div>*/}
                    {/*                <div className="div-tag">Child Div</div>*/}
                    {/*            </label>*/}
                    {/*            <div className="content">*/}

                    {/*                /!*Second Nested Div*!/*/}
                    {/*                <div className="div">*/}
                    {/*                    <input type="checkbox" id="div6"/>*/}
                    {/*                    <label htmlFor="div6">*/}
                    {/*                        <div className="caret icon-sm">*/}
                    {/*                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"*/}
                    {/*                                 viewBox="0 0 24 24" strokeWidth="1.5"*/}
                    {/*                                 stroke="currentColor" className="w-6 h-6">*/}
                    {/*                                <path strokeLinecap="round" strokeLinejoin="round"*/}
                    {/*                                      d="m8.25 4.5 7.5 7.5-7.5 7.5"/>*/}
                    {/*                            </svg>*/}

                    {/*                        </div>*/}
                    {/*                        <div className="icon-sm ms-1 me-2">*/}
                    {/*                            <svg xmlns="http://www.w3.org/2000/svg" width="16"*/}
                    {/*                                 height="16" fill="currentColor" className="bi bi-app"*/}
                    {/*                                 viewBox="0 0 16 16">*/}
                    {/*                                <path*/}
                    {/*                                    d="M11 2a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3zM5 1a4 4 0 0 0-4 4v6a4 4 0 0 0 4 4h6a4 4 0 0 0 4-4V5a4 4 0 0 0-4-4z"/>*/}
                    {/*                            </svg>*/}

                    {/*                        </div>*/}
                    {/*                        <div className="div-tag">Grandchild Div</div>*/}
                    {/*                    </label>*/}
                    {/*                    <div className="content">*/}
                    {/*                        <div className="div-tag">Empty</div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                /!*   END - Second Nested Div*!/*/}

                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        /!*   END - Nested Div*!/*/}

                    {/*    </div>*/}
                    {/*</li>*/}
                    <li className="my-2 left-nav-menu-item" role={"button"} onClick={() => navigate('/connect-page')}>
                        <div className="d-flex justify-content-start align-items-center p-2">
                            <FiPower className={"mx-3"} size={25} />
                            <Link to={"/connect-page"} className={`fs-5 text-decoration-none ${lightMode ? "text-black" : "text-white"}`}>Your Connect Page</Link>
                        </div>
                    </li>
                    <li className="my-2 left-nav-menu-item" role={"button"} onClick={() => navigate('/links')}>
                        <div className="d-flex justify-content-start align-items-center p-2">
                            <IoIosLink className="mx-3" size={25}/>
                            <Link to={"/links"} className={`fs-5 text-decoration-none ${lightMode ? "text-black" : "text-white"}`}>Links</Link>
                        </div>
                    </li>
                    <li className="my-2 left-nav-menu-item" role={"button"} onClick={() => navigate('/qr-codes')}>
                        <div className="d-flex justify-content-start align-items-center p-2">
                            <IoQrCodeOutline className="mx-3" size={25}/>
                            <Link to={"/qr-codes"} className={`fs-5 text-decoration-none ${lightMode ? "text-black" : "text-white"}`}>QR Codes</Link>
                        </div>
                    </li>
                    {/*<li className="my-3 left-nav-menu-item" role={"button"} onClick={() => navigate('/custom-links')}>*/}
                    {/*    <div className="d-flex justify-content-start align-items-center">*/}
                    {/*        <IoIosLink className="mx-3 h-10 w-10"/>*/}
                    {/*        <Link to={"/custom-links"} className={`fs-3 text-decoration-none ${lightMode ? "text-black" : "text-white"}`}>Custom Links</Link>*/}
                    {/*    </div>*/}
                    {/*</li>*/}
                    <li className="my-2 left-nav-menu-item" role={"button"} onClick={() => navigate('/analytics')}>
                        <div className="d-flex justify-content-start align-items-center p-2">
                            <IoAnalytics className="mx-3" size={25}/>
                            <Link to={"/analytics"} className={`fs-5 text-decoration-none ${lightMode ? "text-black" : "text-white"}`}>Analytics</Link>
                        </div>
                    </li>
                    <li className="my-2 left-nav-menu-item" role={"button"} onClick={() => navigate('/developers')}>
                        <div className="d-flex justify-content-start align-items-center p-2">
                            <GiBrain className="mx-3" size={25}/>
                            <Link to={"/analytics"} className={`fs-5 text-decoration-none ${lightMode ? "text-black" : "text-white"}`}>Developers</Link>
                        </div>
                    </li>
                    {subscriptionRole && subscriptionRole.role === "no_subscription" && (
                        <li  className="bg-light mt-3 rounded-4 fade-in-3">
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-center mt-3">
                                    <h4 className="text-hd fs-4 fw-bold mt-3 text-center">Need more?</h4>
                                </div>
                                <div className="mx-4 mb-3">
                                    <BlockButton label={"Upgrade Now!"} type={"button"} onClick={() => navigate("/payments/pricing")}/>
                                </div>
                            </div>
                        </li>
                    )}
                    <li className="bg-light mt-5 rounded-4 fade-in-3">
                        {account && account.subscriptionRole !== "no_subscription" && (
                            <div className="d-flex justify-content-center mt-3">
                                <p className="text-hd fs-5 fw-bold">Monthly Usage</p>
                            </div>
                        )}
                        {account && account.subscriptionRole === "no_subscription" && (
                            <div className="d-flex justify-content-center mt-3">
                                <h4 className="text-hd fs-5 fw-bold">Free Tier Usage</h4>
                            </div>
                        )}
                        <div className="mx-6 w-100 d-flex  mt-3">
                            <LinkMonthlyUsage/>
                        </div>
                        <div className="mx-6 w-100 d-flex  my-3">
                            <QrMonthlyUsage/>
                        </div>
                    </li>


                </ul>
            ): <></>}
        </>
    )
}

export default LeftSubMenu