import {APICore} from "../library/utilities/apiCore";
import {
    ApiKey,
    Click, ConnectPage, ConnectPageButton, ConnectPageComponent, ConnectPageStyle, ConnectPageText, MonthlyUsage,
    NewLinkObject,
    NewQrCodeObject,
    QrCodeObject,
    RedFile,
    UrlDocument
} from "../types/AppObjectTypes";
import axios from "axios"
import toast from "react-hot-toast";
import {RedUser} from "../types/AuthTypes";




//links services
export const createLink = async(data:NewLinkObject) => {
    const createRes = await new APICore().create(`create/link`,data)
    return createRes.data.urlDocument
}

export const deleteLink = async (accountKey:string,data:UrlDocument) => {
    const urlParams = new URLSearchParams({
        accountKey:accountKey,
        partitionKey:data.pk
    }).toString()
    const deleteRes = await new APICore().delete(`delete/link?${urlParams}`)
    return deleteRes.data
}

export const getAccountLinks = async(accountKey:string) => {
    const urlParams = new URLSearchParams({
        accountKey:accountKey
    }).toString()
    const res = await new APICore().get(`account/links?${urlParams}`,{})
    return res.data.filteredUrlDocuments as UrlDocument[]
}

export const updateLink = async (link:UrlDocument) => {
    const urlParams = new URLSearchParams({
        partitionKey:link.pk,
        accountKey:link.accountKey ? link.accountKey : ""
    }).toString()
    const res = await new APICore().update(`update/link?${urlParams}`,link)
    return res.data as UrlDocument
}
//Qr Codes
export const getAccountQrCodes = async (accountKey:string) => {
    const res = await new APICore().get(`account/qr?accountKey=${accountKey}`,{})
    return res.data as QrCodeObject[]
}

export const createQrCode = async (data:NewQrCodeObject) => {
    const res = await new APICore().create(`create/qr`,data)
    return res.data as QrCodeObject
}

export const updateQrCode = async (data:QrCodeObject) => {
    const res = await new APICore().update(`update/qr`,data)
    return res.data as QrCodeObject
}

export const getQrCode = async (accountKey:string,linkPk:string) => {
    const urlParams = new URLSearchParams({
        accountKey:accountKey,
        partitionKey:linkPk
    }).toString()
    const res = await new APICore().get(`/retrieve/qr?${urlParams}`,{})
    return res.data as QrCodeObject
}

//Clicks
export const getClicks = async (linkPk:string, accountKey:string) => {
    console.log("fetching clicks")
    const urlParams = new URLSearchParams({
        accountKey:accountKey,
        partitionKey:linkPk
    }).toString()
    const res = await new APICore().get(`/retrieve/clicks?${urlParams}`,{})
    return res.data.linkClicks as Click[]
}

//Files
export const getAccountRedFiles = async() => {
    const res = await new APICore().getAccountFiles()
    return res.data as RedFile[]
}

export const uploadFile = async (data:any) => {
    try{
        const file = data.file[0]
        if(file.size > 10000000){
            throw Error("file larger than 10 mb")
        }
        const fileName = file.name
        const url = import.meta.env.VITE_PUBLIC_FILE_API_URL
        const res = await new APICore().get(`${url}/generate`, {key:fileName, type: file.type, size: file.size})

        //upload file to pre-signed url
        const uploadRes = await axios.put(res.data.url, file, {
            headers: {
                'Content-Type': file.type,
            },
            withCredentials: false
        })
    }catch(e){
        console.log("error: ",e)
        toast.error("something went wrong err: "+e)
    }
}

export const deleteDynamoFile = async (file:RedFile) => {
    const url = import.meta.env.VITE_PUBLIC_FILE_API_URL
    const data = {fileToDelete:file}
    const res = await new APICore().create(`${url}/delete/file`,data)
    return res.status
}
//Developer Api
export const generateApiKey = async() => {
    const res = await new APICore().create(`/developer/api-key`)
    return res.data as ApiKey
}

export const getAccountApiKeys = async () => {
    const res = await new APICore().get(`/developer/account/api-keys`)
    return res.data as ApiKey[]
}

//montly usage tracking
export const getMonthlyUsage = async () => {
    const res = await new APICore().get(`/usage`)
    return res.data as MonthlyUsage
}

export const getMe = async () => {
    const res = await new APICore().get("/getme")
    return res.data.user as RedUser
}

//connect page methods

export const getAccountConnectPage = async () => {
    const res = await new APICore().get("/account/connect-page")
    return res.data as ConnectPage
}

export const getConnectPageByName = async (pageName:string) => {
    const url = import.meta.env.VITE_PUBLIC_UNPROTECTED_API_URL || ""
    const res = await axios.post(`${url}/connect-page/view`,{pageName:pageName})
    return res.data as ConnectPage
}

export const claimPage = async (templateName:string, pageName:string) => {
    const data = {templateName: templateName, pageName:pageName}
    const res = await new APICore().create("/create/connect-page",data)
    return res.data as ConnectPage
}

export const updateComponents = async (components:ConnectPageComponent[]) => {
    const data = {components: components}
    const res = await new APICore().update("/update/connect-page/components",data)
    return res.data as ConnectPageComponent[]
}

export const updateConnectStyle = async (pageStyle:ConnectPageStyle) => {
    const res = await new APICore().update(`/update/connect-page/style`,{pageStyle:pageStyle})
    return res.data as ConnectPageStyle
}

export const createNewComponent = async (newComponent:ConnectPageComponent | ConnectPageButton | ConnectPageText) => {
    const data = {newComponent:newComponent}
    const res = await new APICore().create(`/create/connect-page/component`,data)
    return res.data as ConnectPageComponent
}

export const deleteConnectComponent = async (componentToDelete:ConnectPageComponent | ConnectPageButton | ConnectPageText) => {
    const data = {deleteComponent: componentToDelete}
    const res = await new APICore().create("/delete/connect-page/component",data)
    return res.status
}